import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useAuth } from '../../contexts/AuthContext'
import { config, createOrder, encode, getOrder } from '../../models/orders'
import { createPurchase, updatePurchaseUser, getPurchaseByUser } from '../../models/purchases'

import Error from '../../containers/Signup/Signup.module.css'
import css from './CheckoutPage.module.css'

import { CheckoutForm } from '../../forms'
import { getPromocode } from '../../models/promocodes'
import { FieldTextInput } from '../../components'

const CheckoutPage = () => {
    const price = process.env.REACT_APP_PIVOTEA_PRICE || '1'

    const priceFloat = parseFloat(
        (typeof price === 'string' ? price.replace('$', '').replace(',', '') : price) || 0
    )

    const formatCurrency = (value) => {
        const opciones = { style: 'currency', currency: 'MXN' }
        return value.toLocaleString('es-MX', opciones)
    }

    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth()

    const navigate = useNavigate()
    //const baseUrl = process.env.REACT_APP_FRONT_END_URL || 'http://localhost:3000'
    const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3501'

    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [purchaseId, setPurchaseId] = useState()

    const [order, setOrder] = useState({})
    const [purchase, setPurchase] = useState({})
    const [encodedInformation, setEncodedInformation] = useState({})
    const [configData, setConfigData] = useState({})

    const [discount, setDiscount] = useState(0)
    const [totalPrice, setTotalPrice] = useState(priceFloat)

    const [promocode, setPromocode] = useState('')
    const [promoError, setPromoError] = useState('')
    const [promoSuccess, setPromoSuccess] = useState('')
    const [purchaseExists, setPurchaseExists] = useState(false);

    const getPromoCodeCheckout = async (promo_code) => {
        try {
            const promo = await getPromocode(promo_code)
            const promocodeDetails = promo?.data?.promocode[0]

            if (promocodeDetails && promocodeDetails.percentage && promocodeDetails.quantity > 0) {
                const discountAmount = (priceFloat * promocodeDetails.percentage) / 100
                const newTotal = priceFloat - discountAmount

                setDiscount(discountAmount)
                setTotalPrice(newTotal >= 0 ? newTotal : 0)
                setPromoError('')
                setPromoSuccess('¡Código promocional aplicado con éxito!')
            } else {
                setPromoError('Código promocional inválido o agotado')
                setPromoSuccess('')
            }
        } catch (error) {
            setPromoError('Error al aplicar el código promocional')
            setPromoSuccess('')
        }
    }

    const handlePromoCodeSubmit = (e) => {
        e.preventDefault()
        if (promocode.trim()) {
            getPromoCodeCheckout(promocode)
        } else {
            setPromoError('Por favor, introduce un código promocional')
        }
    }

    const getPurchase = async (id) => {
        try {
            const response = await getPurchaseByUser(id);
            if (response?.data?.purchase?.length > 0) {
                // Si hay una compra, no cargues la página de checkout.
                setPurchaseExists(true);
                navigate('/');
            } else {
                setPurchaseExists(false);
                createNewOrder();
            }
        } catch (error) {
            console.error('Error al obtener la compra:', error);
        }
    };

    const createNewOrder = async () => {
        // Generate order
        const newOrder = {
            tag: 'pivotea',
        }

        await createOrder(newOrder)
            .then((response) => {
                setOrder(response.data)
                // Generate purchase
                const newPurchase = {
                    id_user: authUser?.id,
                    id_order: response.data._id,
                    status: response.data.status,
                    total: totalPrice,
                    country: encodedInformation.country,
                    state: encodedInformation.state,
                    phoneCode: encodedInformation.phoneCode,
                    phone: encodedInformation.phone,
                    promo_code: promocode,
                    name: encodedInformation.name,
                    lastname: encodedInformation.lastName,
                    email: encodedInformation.email,
                }

                createPurchase(newPurchase)
                    .then((response) => {
                        setPurchase(response.data.purchase[0])
                        setPurchaseId(response.data.purchase[0].id_purchase)
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const handleGetOrder = async () => {
        await getOrder(purchase.id_order)
            .then((response) => {
                setOrder(response.data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getConfiguration = async () => {
        await config()
            .then((response) => {
                setConfigData(response.data)
            })
            .catch((error) => {
                console.error('Error al obtener la configuración:', error)
            })
    }

    useEffect(() => {
        if (!isLoggedIn) {
            const authCookie = Cookies.get('authUser')

            if (authCookie) {
                console.log('Login found in cookies')
                const user = JSON.parse(authCookie)
                setAuthUser(user)
                setIsLoggedIn(true)
            } else {
                console.log('No auth cookie found, redirecting to login')
                navigate('/login')
            }
        }
    }, [])

    useEffect(() => {
        if (isLoggedIn && authUser?.id) {
            getPurchase(authUser?.id)
            getConfiguration()
        }
    }, [isLoggedIn, authUser])

    useEffect(() => {
        if (!order?._id && purchase.id_purchase) {
            handleGetOrder()
        }
    }, [order, purchase])

    const handleOnSubmitEncode = async (values) => {
        setLoading(true);
        try {
            const totalWithDiscount = totalPrice;
            values.total = totalWithDiscount;
            values.promo_code = promocode;
            values.discountAmount = discount;

            if (!purchaseId) {
                throw new Error("Missing purchase ID");
            }

            if (totalWithDiscount === 0) {
                await updatePurchaseUser(purchaseId, { ...values, status: 'Pagado' })
                    .then(() => {
                        setTimeout(() => {
                            navigate('/checkout/return');
                        }, 2000);
                    })
                    .catch((error) => {
                        console.error('Error al actualizar la compra:', error);
                    });

                return;
            }

            const toBeEncoded = {
                total: totalWithDiscount,
                country: values.country,
                state: values.state,
                phoneCode: values.phoneCode,
                phone: values.phone,
                promo_code: promocode,
                name: values.name,
                lastname: values.lastName,
                folio: order._id,
                description: 'Pivotea',
                email: values.email,
                returnUrl: apiUrl + '/purchases/return',
                noticeUrl: apiUrl + '/purchases/notice',
            };
            const encodedInformation = await encode(toBeEncoded);
            setEncodedInformation(encodedInformation.data);
            await updatePurchaseUser(purchaseId, values)
                .then(() => {
                    debugger
                    console.log('Updated purchase successfully');
                })
                .catch((error) => {
                    console.log('Error updating purchase', error);
                });
            debugger
        } catch (error) {
            console.error('Error processing the payment:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 2500);
        }
    };

    useEffect(() => {
        if (encodedInformation?.description) {
            const checkoutForm = document.getElementById('checkout')

            // Validate if the form has the values
            if (!checkoutForm) {
                return
            }
            if (!checkoutForm.querySelector('input[name="montoInicial"]')) {
                return
            }

            checkoutForm.submit()
        }
    }, [encodedInformation])

    if (purchaseExists) {
        return null; 
    }

    return (
        <div className={css.layout}>
            {loading && (
                <div className={css.spinnerOverlay}>
                    <div className={css.spinnerContainer}>
                        <div className={css.spinner}></div>
                    </div>
                </div>
            )}

            <div className={css.left}>
                <h1>Sección de pagos</h1>
                <CheckoutForm
                    order={order}
                    purchase={purchase}
                    setDisabled={setDisabled}
                    onSubmit={handleOnSubmitEncode}
                />

                <form action={process.env.REACT_APP_CHECKOUT_URL} method="POST" id="checkout">
                    <input type="hidden" name="cveTienda" value={configData?.cveTienda} />
                    <input type="hidden" name="llaveTienda" value={configData?.llaveTienda} />
                    <input type="hidden" name="codigoTienda" value={configData?.codigoTienda} />
                    <input type="hidden" name="servicio" value={configData?.servicio} />
                    <input type="hidden" name="montoInicial" value={encodedInformation?.total} />
                    <input type="hidden" name="nombre" value={encodedInformation?.name} />
                    <input type="hidden" name="folioTienda" value={encodedInformation?.folio} />
                    <input type="hidden" name="descServicio" value={encodedInformation?.description} />
                    <input type="hidden" name="email" value={encodedInformation?.email} />
                    <input type="hidden" name="urlRegreso" value={encodedInformation?.returnUrl} />
                    <input type="hidden" name="urlAviso" value={encodedInformation?.noticeUrl} />
                    <input type="hidden" name="cantidad" value="0c" />
                    <input type="hidden" name="tipoMoneda" value="706573" />
                    <input type="hidden" name="tipoTransaccion" value="6b7873697c" />
                    <input type="hidden" name="idioma" value="0c" />
                    <input type="hidden" name="q" value="1" />
                    <input type="hidden" name="campus" value="7169" />
                    <input type="hidden" name="term" value="0f0d0f0e0d0c" />
                    <input type="hidden" name="opcional" value="" />
                </form>
            </div>
            <div className={css.right}>
                <h2>Resumen de compra</h2>
                <p>Pivotea</p>

                <div className={css.row}>Pago: {formatCurrency(totalPrice)}</div>

                <div className={css.promocodeSection}>
                    <h3>¿Tienes un código promocional?</h3>
                    <form onSubmit={handlePromoCodeSubmit}>
                        <FieldTextInput
                            type="text"
                            placeholder="Introduce tu código"
                            value={promocode}
                            onChange={(e) => setPromocode(e.target.value)}
                        />
                        <button type="submit" className={css.button}>
                            Aplicar
                        </button>
                    </form>
                    {promoError && <p className={Error.errorMsg}>{promoError}</p>}
                    {promoSuccess && <p className={css.successMsg}>{promoSuccess}</p>}
                </div>
            </div>
        </div>
    )
}

export default CheckoutPage
